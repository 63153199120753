.box {
  background: #ffffff;
  color: #333333;
  padding: 2.25rem;
  /* text-align: left; */
  height: 100%;
  margin: 0 auto;
  max-width: 800px;
}

.left-column {
  width: 48%;
  display: block;
  /* margin: 0 10px; */
  float: left;
  max-width: 400px;
  padding: 0 20px 0 0;
}

.right-column {
  width: 43%;
  display: block;
  /* margin: 0 10px; */
  max-width: 400px;
  padding: 10px 25px;
  float: left;
  box-shadow: 0px 5px 10px 0px rgba(4, 10, 41, 0.3);
  border-radius: 10px;
  background: #f5f5f5;
  font-size: 14px;
}

th {
  color: #2f7bbd;
  padding: 0 10px;
}

tr.active {
  background: #2f7bbd;
  color: #ffffff;
  border-radius: 10px;
}

td {
  line-height: 25px;
  padding: 0 10px;
}

.form-group label {
  font-size: 1.25em;
  margin: 0.5rem 0;
  font-weight: 500;
  display: inline-block;
}

.form-group input {
  padding: 0.25rem 0 0.5rem 10px;
  font-size: 1.625rem;
  font-weight: 500;
  line-height: 1;
  /* text-align: center; */
  -webkit-appearance: none;
  margin: 0;
  -webkit-transition: border 0.2s ease-in-out;
  transition: border 0.2s ease-in-out;
  /* vertical-align: middle; */
  border-radius: 5px;
  border: 1px solid #c9cfd9;
  width: 100%;
  max-width: 372px;
  color: #2f7bbd;
}

.total-wrapper {
  border-radius: 5px;
  background: #2f7bbd;
  padding: 1rem 2.25rem;
  text-align: center;
  margin-top: 1.5rem;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.uppercase-title.small {
  font-size: 0.625rem;
}
.uppercase-title.no-top-margin {
  margin-top: 0;
}
.uppercase-title {
  font-size: 0.825rem;
  color: #1e9fc4;
  letter-spacing: 2px;
  line-height: 1rem;
  text-transform: uppercase;
  font-weight: 600;
  margin-top: 2.25rem;
  margin-bottom: 0.5rem;
  color: #a2acbc;
  display: block;
}

.total-text-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #ffffff;
}

.total {
  font-size: 2.5em;
  font-weight: 700;
  line-height: 1;
}

.dollar-sign {
  align-self: flex-start;
  font-weight: 700;
  font-size: 1.5em;
  margin-top: 0.5em;
  line-height: 1;
  margin-right: 0.5rem;
}

.total-time {
  align-self: flex-end;
  font-weight: 600;
  /* display: none; */
}

.cta-link-blue.no-top-margin {
  margin-top: 0;
  /* font-size: 2.5rem; */
  padding: 0.75em 1rem;
}

.cta-link-blue {
  vertical-align: middle;
  /* background: #2f7bbd;
  border-radius: 0; */
  padding: 0.88rem 1.5rem;
  text-decoration: none;
  /* -webkit-box-shadow: 0 5px 10px 0 rgba(4, 10, 41, 0.3);
  box-shadow: 0 5px 10px 0 rgba(4, 10, 41, 0.3); */
  border: none;
  /* font-size: 0.825rem; */
  /* color: #2f7bbd; */
  letter-spacing: 2px;
  line-height: 1rem;
  text-transform: uppercase;
  font-weight: 600;
  -webkit-transition: background 0.2s ease-in-out;
  transition: background 0.2s ease-in-out;
  color: #fff;
  margin-top: 2.25rem;
}

.h5 {
  font-size: 1rem;
  line-height: 1.5rem;
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 500;
  padding: 0 10px;
}

.sync-rate-table,
.volume-rate-table {
  text-align: left;
  width: 100%;
  margin-top: 0.5rem;
  border-spacing: 0;
}

.sync-rate-table + .h5,
.volume-rate-table + .h5 {
  border-top: 1px solid #f3f5f9;
  /* margin-top: 1.5rem; */
  display: block;
  padding-top: 0.5rem;
  font-size: 10px;
}

@media screen and (max-width: 780px) {
  .left-column,
  .right-column {
    width: 100% !important;
    padding: 0 !important;
  }
  .right-column {
    margin: 20px 0 0 0 !important;
  }
}
